import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Alert,
  Grid2,
  Button,
  Stack,
  Chip,
  Snackbar,
} from "@mui/material";
import dayjs from "dayjs";
import { ContactEmergency, Person } from "@mui/icons-material";

export default function UserDetails({ loanData, user, setRefresh, refresh }) {
  const [loading, setLoading] = useState(false);
  const [rloading, setRLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [overdue, setOverdue] = useState(0);
  const [defaulted, setDefaulted] = useState(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // "error", "warning", "info", "success"
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (user) {
      setOverdue(checkOverdue(user?.loans));
      setDefaulted(checkDefaulted(user?.loans));
    }
  }, [user]);

  const checkOverdue = (loans) => {
    return loans.some((loan) => {
      return loan.status === "Overdue" || loan.overdueDays > 0;
    });
  };

  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Defaulted" || daysSinceRepayment > 60;
    });
  };

  // Function to update loan status
  const updateLoanStatus = async (loanId, status) => {
    if (status == "Approved") setUpdating(true);
    else setRLoading(true);
    try {
      const response = await fetch(`/api/loans/${loanId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
        credentials: "include",
      });
      const data = await response.json();
 
      if (response.ok) {
        setSeverity("success");
        setMessage(data.message);
        setOpen(true);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 3000);
      } else {
        setSeverity("error");
        setMessage(data.message);
        setOpen(true);
      }
    } catch (error) {
      setSeverity("error");
      setMessage("Oops could not update status!");
      setOpen(true);
    } finally {
      setUpdating(false);
      setRLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Grid2 container spacing={3} mb={3}>
        <Grid2 size={{ xs: 12, md: 9 }}>
          <Typography variant="title" mb={2}>
            Loan Details
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack spacing={1}>
            <Box display="flex" gap={1}>
              <Alert sx={{ flexGrow: 1 }}>Principal</Alert>
              <Typography
                textAlign="center"
                variant="body1"
                sx={{ m: "auto", display: "block", flexGrow: 1 }}
              >
                KSh{" "}
                {(loanData?.amount - 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Alert sx={{ flexGrow: 1 }}>Interest</Alert>
              <Typography
                flexGrow={1}
                textAlign="center"
                variant="body1"
                sx={{ m: "auto", display: "block" }}
              >
                KSh{" "}
                {(loanData?.interest - 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Alert sx={{ flexGrow: 1 }}>Repayment</Alert>
              <Typography
                flexGrow={1}
                textAlign="center"
                variant="body1"
                sx={{ m: "auto", display: "block" }}
              >
                KSh{" "}
                {(loanData?.paymentAmount - 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Box>
            <Typography
              flexGrow={1}
              textAlign="left"
              variant="body2"
              sx={{ m: "auto", display: "block" }}
            >
              Loan Date:{" "}
              {dayjs(loanData?.createdAt).format("YYYY MMM, D - HH:mm:ss")}
            </Typography>
            <Typography
              flexGrow={1}
              textAlign="left"
              variant="body2"
              sx={{ m: "auto", display: "block" }}
            >
              Due Date: {dayjs(loanData?.repaymentDate).format("YYYY MMM, D")}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Typography variant="title" mb={2}>
            Action
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack spacing={3}>
            {loanData?.status == "Pending" && (
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                onClick={() => updateLoanStatus(loanData.id, "Approved")}
              >
                {updating ? "Updating..." : "Approve"}
              </Button>
            )}
            {loanData?.status == "Pending" && (
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                color="error"
                onClick={() => updateLoanStatus(loanData.id, "Rejected")}
              >
                {rloading ? "Updating..." : "Reject"}
              </Button>
            )}
            {(loanData?.status == "Rejected" ||
              loanData?.status == "Waiting") && (
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                color="warning"
                onClick={() => updateLoanStatus(loanData.id, "Pending")}
              >
                {updating ? "Updating..." : "Revoke"}
              </Button>
            )}
            <Divider />
            <Box p={1} component={Stack} gap={1}>
              <Typography variant="body2" flexGrow={1}>
                <strong style={{ fontWeight: 500 }}>Overdue Loans:</strong>{" "}
                <Chip
                  size="small"
                  color={!overdue ? "success" : "error"}
                  label={overdue ? "Yes" : "No"}
                />
              </Typography>
              <Typography variant="body2" flexGrow={1}>
                <strong style={{ fontWeight: 500 }}>Defaulted Loans:</strong>{" "}
                <Chip
                  size="small"
                  color={!defaulted ? "success" : "error"}
                  label={defaulted ? "Yes" : "No"}
                />
              </Typography>
            </Box>
          </Stack>
        </Grid2>
      </Grid2>

      <Typography variant="title" mb={2}>
        User Information
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Stack>
        <Grid2 container spacing={2}>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <Person fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="h6" sx={{ margin: "auto" }} flexGrow={1}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Chip
                color={user?.isVerified ? "success" : "warning"}
                label={user?.isVerified ? "Premium" : "Regular"}
              />
            </Box>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.alternativePhone}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" color="warning" label={user?.gender} />
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" label={user?.dateOfBirth} />
            </Typography>
            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 4 }}
              variant="title"
              gutterBottom
            >
              Credit Score
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Manual Override: </strong>{" "}
              {user?.CreditScore.manualOverride ? "Yes" : "No"}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>
                Manual Override Limit:{" "}
              </strong>{" "}
              {(user?.CreditScore.manualCreditLimit - 0)?.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Credit Limit: </strong>{" "}
              {(user?.CreditScore.creditLimit - 0)?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Credit Score: </strong>{" "}
              {user?.CreditScore.score.toFixed(0)}%
            </Typography>

            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 4 }}
              variant="title"
              gutterBottom
            >
              Work Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Employment Type: </strong>{" "}
              {user?.typeOfWork}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Monthly Income: </strong>{" "}
              {user?.monthlyIncome}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Pay Day: </strong>{" "}
              {user?.payDay}
            </Typography>
            <br />
            <Typography
              sx={{ fontSize: "medium", mt: 2 }}
              variant="title"
              gutterBottom
            >
              Location Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>County: </strong>{" "}
              {user?.county}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>SubCounty: </strong>{" "}
              {user?.subcounty}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Ward: </strong> {user?.ward}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Detailed Address: </strong>{" "}
              {user?.detailedAddress}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Emergency Contacts</Typography>
            <Divider />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <ContactEmergency color="primary" fontSize="large" />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.relationship}
            </Typography>
            <Chip label="1" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.relationship}
            </Typography>
            <Chip label="2" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>
        </Grid2>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
