import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Refresh } from "@mui/icons-material";

// Component to fetch and display user details
function UserDetails({ userId }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch(`/api/users/me/${userId}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to fetch user details");
      })
      .then((data) => setUser(data))
      .catch((error) => console.error(error));
  }, [userId]);

  if (!user) return "Loading...";
  return `${user.firstName} ${user.lastName}`;
}

export default function Payments() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("amountPaid");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setLoading(true);
    let url = `/api/payments?offset=${offset * 10}&limit=10${
      searchValue ? `&${column}=${searchValue}` : ""
    }`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Error fetching payments");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, searchValue, column, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchValue(value);
    setOffset(0);
  };

  const handleOpenDetails = (payment) => {
    const userId = payment.loan.userId;

    // Fetch user details based on the userId
    fetch(`/api/users/me/${userId}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to fetch user details");
      })
      .then((userData) => {
        setSelectedPayment({ ...payment, user: userData });
        setOpenDetails(true);
      })
      .catch((error) => console.error("Error fetching user details:", error));
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelectedPayment(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9 }}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mb: 2 }}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ mx: 2 }} />
            )}
          />
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: "0px 10px 30px #60606040",
            p: "1em",
          }}
          component={Card}
        >
          <Box display="flex" gap={2} alignItems="center" mb={2}>
            <FormControl>
              <InputLabel size="small">Search by...</InputLabel>
              <Select
                label="Search by..."
                size="small"
                onChange={(e) => setColumn(e.target.value)}
                value={column}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="amountPaid">Amount Paid</MenuItem>
                <MenuItem value="paymentDate">Payment Date</MenuItem>
                <MenuItem value="mpesaReceiptNumber">Receipt Number</MenuItem>
                <MenuItem value="loan.orderNumber">Order Number</MenuItem>
                <MenuItem value="loan.status">Loan Status</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label="Search..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          {loading ? (
            <Typography align="center">Loading...</Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Amount Paid</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Receipt Number</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Loan Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.length > 0 ? (
                    data.data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Chip label={offset * 10 + index + 1} />
                        </TableCell>
                        <TableCell>
                          <UserDetails userId={item.loan.userId} />
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.amountPaid.toLocaleString()}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {dayjs(item.paymentDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.mpesaReceiptNumber}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          {item.loan?.orderNumber || "N/A"}
                        </TableCell>
                        <TableCell onClick={() => handleOpenDetails(item)}>
                          <Chip
                            color={
                              item.loan?.status === "Paid" ? "success" : "warning"
                            }
                            label={item.loan?.status || "Unknown"}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleOpenDetails(item)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Payments Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box mt={2}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
              />
            )}
          </Box>

          {selectedPayment && (
            <Dialog open={openDetails} onClose={handleCloseDetails} fullWidth>
              <DialogTitle>Payment and User Details</DialogTitle>
              <Divider />
              <DialogContent>
                <Stack spacing={1}>
                  <Typography variant="h6">Payment Information</Typography>
                  <Typography>
                    Amount Paid: {selectedPayment.amountPaid.toLocaleString()}
                  </Typography>
                  <Typography>
                    Payment Date:{" "}
                    {dayjs(selectedPayment.paymentDate).format("YYYY-MM-DD")}
                  </Typography>
                  <Typography>
                    Receipt Number: {selectedPayment.mpesaReceiptNumber}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">User Information</Typography>
                  <Typography>
                    Name: {selectedPayment.user?.firstName}{" "}
                    {selectedPayment.user?.lastName}
                  </Typography>
                  <Typography>
                    Email: {selectedPayment.user?.email || "N/A"}
                  </Typography>
                  <Typography>
                    Phone: {selectedPayment.user?.phoneNumber || "N/A"}
                  </Typography>
                  <Typography>
                    ID Number: {selectedPayment.user?.idCardNumber || "N/A"}
                  </Typography>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDetails}>Close</Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
