import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ContactEmergency,
  DateRange,
  Emergency,
  Money,
  Person,
  SupportAgent,
  Refresh,
} from "@mui/icons-material";
import { CurrencyDollar, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function RMApplications(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Pending");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleTabChange = (event, newValue) => {
    setOffset(0);
    setSelected(newValue);
  };

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    let url = `/api/loans?offset=${
      offset * 10
    }&includeUsers=true&includePayments=true&includeFollowUps=true&loanStatus=${selected}`;

    if (startDate) {
      url += `&startDate=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=trueincludeFollowUps=true&loanStatus=${selected}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box mt={9}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Tabs value={selected} onChange={handleTabChange}>
          <Tab label="Pending" value="Pending" />
          <Tab label="Waiting" value="Waiting" />
          <Tab label="Rejected" value="Rejected" />
          <Tab label="Approved" value="Approved" />
        </Tabs>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              size="small"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: "0px 10px 30px #60606040",
          p: "1em",
        }}
        component={Card}
      >
        <Box sx={{ padding: "0" }}>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box flexGrow={1}>
              <Typography variant="h5"></Typography>
            </Box>
            <FormControl>
              <InputLabel size="small">Search by...</InputLabel>
              <Select
                label="Search by..."
                size="small"
                onChange={(e) => setColumn(e.target.value)}
                value={column}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID No.</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label="Search..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">SN</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Order No</TableCell>
                    <TableCell>Term</TableCell>
                    <TableCell>Repayment</TableCell>
                    <TableCell>Applied</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data?.data?.length > 0 ? (
                    data.data.map((item, index) => {
                      return (
                        <LoanBody
                          key={index}
                          index={index}
                          offset={offset}
                          item={item}
                          selected={selected}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>No Loans Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box mt={1}>
            {data && (
              <Pagination
                count={Math.ceil(data.total / 10)}
                page={offset + 1}
                onChange={(e, value) => setOffset(value - 1)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const LoanBody = ({ item, index, offset, refresh, setRefresh, selected }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (item) {
      fetch(`/api/users?includeLoans=true&limit=1&id=${item.userId}`, {
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          if (data && data.data.length > 0) {
            setUser(data.data[0]);
          }
        })
        .catch((e) => {});
    }
  }, [item, refresh]);


  return (
    <>
      <TableRow
        onClick={() => {
          navigate(`/rm/applications/${item.id}`);
        }}
        key={index}
      >
        <TableCell padding="checkbox">
          <Chip label={offset * 10 + index + 1} />
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {user?.firstName ? user?.firstName : "..."}{" "}
            {user?.lastName ? user?.lastName : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            color={user?.isVerified ? "success" : "warning"}
            label={user?.isVerified ? "Premium" : "Regular"}
          />
        </TableCell>

        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item?.orderNumber}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item?.term}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip label={item?.repaymentDate} />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              label={dayjs(item?.createdAt).format("YYYY MMM, D - HH:mm:ss")}
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color="primary"
              label={
                "KSh " +
                (item.remainingAmount - 0).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }
            />
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

