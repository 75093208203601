import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Navbar from "./Navbar";
import Home from "../Pages/Home";
import Settings from "../Pages/Settings";
import UsersHome from "./Users/UsersHome";
import RMDashboard from "../Sections/RiskManagement/RMDashboard";
import RMApplications from "../Sections/RiskManagement/RMApplications";
import RMLoans from "../Sections/RiskManagement/RMLoans";
import RMCustomers from "../Sections/RiskManagement/RMCustomers";
import CSDashboard from "../Sections/CustomerSupport/CSDashboard";
import DCDashboard from "../Sections/DebtCollection/DCDashboard";
import CSCustomers from "../Sections/CustomerSupport/CSCustomers";
import DCLoans from "../Sections/DebtCollection/DCLoans";
import CSMessages from "../Sections/CustomerSupport/CSMessages";
import NotFound from "../Pages/NotFound";
import RMCreditLimits from "../Sections/RiskManagement/RMCreditLimits";
import CSEnquiries from "../Sections/CustomerSupport/CSEnquiries";
import DCAgentsHome from "../Sections/DebtCollection/DCAgentsHome";
import DCUsers from "../Sections/DebtCollection/DCUsers";
import CSUsers from "../Sections/CustomerSupport/CSUsers";
import RMUsers from "../Sections/RiskManagement/RMUsers";
import DCAssigned from "../Sections/DebtCollection/DCassigned";
import DCUsersHome from "../Sections/DebtCollection/DCUsersHome";
import Payments from "../Sections/CustomerSupport/Payments";
import LoanManagementPage from "../Sections/RiskManagement/LoanManagementPage";
import CustomerManagement from "../Sections/CustomerSupport/CustomerManagement";
import DCCustomerManagement from "../Sections/DebtCollection/CustomerManagement";
import RMCustomerManagement from "../Sections/RiskManagement/CustomerManagement";
import DCCustomers from "../Sections/DebtCollection/DCCustomers";
import CustomersHome from "../Pages/CustomersHome";

function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes>
            <Route
              path="home"
              element={
                user.Department == "Admin" ? (
                  <Home />
                ) : user.Department == "Risk Management" ? (
                  <RMDashboard />
                ) : user.Department == "Customer Support" ? (
                  <CSDashboard />
                ) : (
                  <DCDashboard />
                )
              }
            />
            {(user.Department == "Risk Management" ||
              user.Department == "Admin") && (
              <>
                <Route
                  path="rm/dashboard"
                  element={<RMDashboard user={user} />}
                />
                <Route
                  path="rm/applications"
                  element={<RMApplications user={user} />}
                />
                <Route
                  path="rm/applications/*"
                  element={<LoanManagementPage user={user} />}
                />
                <Route
                  path="rm/limits"
                  element={<RMCreditLimits user={user} />}
                />
                <Route path="rm/loans" element={<RMLoans user={user} />} />
                <Route
                  path="rm/customers"
                  element={<RMCustomers user={user} />}
                />
                <Route
                  path="rm/customers/*"
                  element={<RMCustomerManagement user={user} />}
                />
                <Route path="rm/users" element={<RMUsers user={user} />} />
              </>
            )}
            {(user.Department == "Customer Service" ||
              user.Department == "Admin") && (
              <>
                <Route
                  path="cs/dashboard"
                  element={<CSDashboard user={user} />}
                />
                <Route path="cs/payments" element={<Payments user={user} />} />
                <Route path="cs/issues" element={<CSEnquiries user={user} />} />
                <Route
                  path="cs/activity"
                  element={<CSMessages user={user} />}
                />
                <Route
                  path="cs/customers"
                  element={<CSCustomers user={user} />}
                />
                <Route
                  path="cs/customers/*"
                  element={<CustomerManagement user={user} />}
                />
                <Route path="cs/users" element={<CSUsers user={user} />} />
              </>
            )}
            {(user.Department == "Debt Collection" ||
              user.Department == "Admin") && (
              <>
                <Route
                  path="dc/dashboard"
                  element={<DCDashboard user={user} />}
                />
                <Route path="dc/loans" element={<DCLoans user={user} />} />
                <Route path="dc/agents" element={<DCAssigned user={user} />} />
                <Route
                  path="dc/customers"
                  element={<DCCustomers user={user} />}
                />
                <Route
                  path="dc/customers/*"
                  element={<DCCustomerManagement user={user} />}
                />
                {user.Role == "Admin" && (
                  <Route
                    path="dc/users"
                    element={<DCUsersHome user={user} />}
                  />
                )}
              </>
            )}
            {user.Department == "Admin" && (
              <Route path="userslist" element={<UsersHome user={user} />} />
            )}
            {user.Department == "Admin" && (
              <Route
                path="customerlist"
                element={<CustomersHome user={user} />}
              />
            )}
            <Route path="settings" element={<Settings user={user} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Box>
    </Box>
  );
}

export default PageRoutes;
