import React, { useState } from "react";
import { Tabs, Tab, Box, Card } from "@mui/material";
import Customers from "./Customers";
import Applications from "./Applications";

export default function CustomersHome(props) {
  const [selected, setSelected] = useState(0);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  return (
    <Box mt={9}>
      <Tabs value={selected} onChange={handleChange} aria-label="customer tabs">
        <Tab label="Customers" sx={{ textTransform: "capitalize" }} />
        <Tab label="Upgrade Applications" sx={{ textTransform: "capitalize" }} />
      </Tabs>
      <Box
        sx={{
          padding: "16px",
          borderRadius: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
        mt={2}
      >
        {selected === 0 && <Customers />}
        {selected === 1 && <Applications />}
      </Box>
    </Box>
  );
}
