import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Pagination,
} from "@mui/material";

export default function Activities({ user }) {
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const offset = (currentPage - 1) * limit;
        const response = await fetch(
          `/api/messages?userId=${user.id}&offset=${offset}&limit=${limit}`
        );
        if (response.ok) {
          const data = await response.json();
          setMessages(data.data);
          setTotalMessages(data.total);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [user.id, currentPage]);

  const handleExpandClick = (messageId) => {
    setExpandedMessageId((prevId) => (prevId === messageId ? null : messageId));
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  return (
    <Box>
      <Stack spacing={2}>
        {messages.map((message) => (
          <Card
            key={message.ID}
            sx={{
              position: "relative",
              boxShadow: "0px 4px 16px #60606040",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ mb: 0, pb: 0 }}>
              <Typography variant="subtitle1">{message.Subject}</Typography>
              <Typography
                sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                variant="body2"
                color="text.secondary"
              >
                {new Date(message.createdAt).toLocaleString()}
              </Typography>
              <Typography
                sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                variant="body2"
                color="text.secondary"
              >
                Sent via: {message.SMS ? "SMS " : ""}
                {message.Email ? "& Email" : ""}
              </Typography>
              <Button
                size="small"
                onClick={() => handleExpandClick(message.ID)}
              >
                {expandedMessageId === message.ID
                  ? "Hide Message"
                  : "View Message"}
              </Button>
            </CardContent>
            <Collapse
              in={expandedMessageId === message.ID}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider />
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {message.Message}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Stack>
      <Pagination
        count={Math.ceil(totalMessages / limit)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
      />
    </Box>
  );
}
