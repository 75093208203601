import React, { useEffect, useState } from "react";
import {
  ArrowForwardIos,
  LineAxis,
  LineAxisOutlined,
  MoneyOff,
  People,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid2,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import MyPieChart from "../../Pages/Home/MyPieChart";
import MyLineChart from "../../Pages/Home/MyLineChart";

export default function DCDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [dloading, setDLoading] = useState(false);
  const [overview, setOverview] = useState({
    customers: {
      total: 0,
      current: 0,
      last: 0,
    },
    payouts: {
      total: 0,
      current: 0,
      last: 0,
    },
    paidin: {
      total: 0,
      current: 0,
      last: 0,
    },
    cs_support: {
      total: 0,
      current: 0,
      last: 0,
      open: 0,
      resolved: 0,
    },
    com: {
      total: 0,
      current: 0,
      last: 0,
      email: 0,
      sms: 0,
    },
    lnp: {
      total: 0,
      current: 0,
      last: 0,
      approved: 0,
      rejected: 0,
    },
    ln_status: [],
  });
  const [customers, setCustomers] = useState({
    users: {
      total: 0,
      current: 0,
      last: 0,
    },
    regular: {
      total: 0,
      current: 0,
      last: 0,
    },
    premium: {
      total: 0,
      current: 3,
      last: 0,
    },
    dormant: {
      total: 0,
      current: 0,
      last: 0,
    },
    genderDistribution: [],
    employmentDistribution: [],
    countyDistribution: [],
    subCountyDistribution: [],
  });
  const [loans, setLoans] = useState({
    principal: {
      total: 0,
      current: 0,
      last: 0,
      repay: 0,
    },
    paid: {
      total: 0,
      current: 0,
      last: 0,
    },
    income: {
      total: 0,
      current: 0,
      last: 0,
    },
    defaulted: {
      total: 0,
      current: 0,
      last: 0,
    },
  });

  useEffect(() => {
    setLoading(true);
    fetchMetrics();
  }, []);

  useEffect(() => {
    if (metrics) {
      setDLoading(true);
      fetchMetrics();
    }
  }, [start, end]);

  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        `/api/loans/debt-collection?start=${start}&end=${end}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();

      setMetrics(data);
    } catch (error) {
    } finally {
      setLoading(false);
      setDLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/loans/overview", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setOverview(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/users/summary", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setCustomers(result);
      } catch (error) {
        console.error("Error fetching user summary:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/loans/loan-dashboard-summary", {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setLoans(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Box sx={{ display: "flex", gap: 2, pt: 2 }}>
        <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Box flexGrow={1}></Box>
        <TextField
          size="small"
          onChange={(e) => setStart(e.target.value)}
          value={start}
          label="Start"
          type="date"
        />
        <TextField
          size="small"
          onChange={(e) => setEnd(e.target.value)}
          value={end}
          label="End"
          type="date"
        />
      </Box>
      <Box
        sx={{ display: "grid", placeContent: "center", height: "35px", mb: 2 }}
      >
        {dloading && (
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </Box>
      <Grid2 container spacing={3}>
        <CardItem
          label="Principal"
          value={loans.principal.total}
          current={loans.principal.current}
          last={loans.principal.last}
          color="#8B5DFF"
          positive={true}
        />
        <CardItem
          label="Paid"
          value={loans.paid.total}
          current={loans.paid.current}
          last={loans.paid.last}
          color="#3D3BF3"
          positive={true}
        />
        <CardItem
          label="Defaulted"
          value={loans.defaulted.total}
          current={loans.defaulted.current}
          last={loans.defaulted.last}
          color="#219B9D"
          positive={true}
        />
        <CardItem
          label="Profits"
          value={overview.paidin.total - overview.payouts.total}
          current={overview.paidin.current - overview.payouts.current}
          last={overview.paidin.last - overview.payouts.last}
          color="#FF8000"
          positive={overview.paidin.current >= overview.payouts.last}
        />
        <ActivityItem
          label="Loan Processing"
          total={overview.lnp.total}
          l1="Approved"
          l2="Rejected"
          v1={overview.lnp.approved}
          v2={overview.lnp.rejected}
          color="#219B9D"
        />
        <ActivityItem
          label="Loan Summary"
          total={loans.principal.repay}
          l1="Paid"
          l2="Defaulted"
          v1={loans.paid.total}
          v2={loans.defaulted.total}
          color="#219B9D"
        />

        <ActivityItem
          label="Customers"
          total={customers.users.total}
          l1="Regular"
          l2="Premium"
          v1={customers.regular.total}
          v2={customers.premium.total}
          color="#219B9D"
        />
        <Grid2 size={{ xs: 12, md: 8 }}>
          <MyLineChart />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <MyPieChart data={overview.ln_status} height={370} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Loan Amount</Typography>
              <BarChart
                xAxis={[
                  {
                    data: ["Active", "Extended", "Overdue", "Defaulted"],
                    scaleType: "band",
                  },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value) => {
                      if (value >= 1000000) {
                        return `${(value / 1000000).toFixed(0)}m`; // Format values in millions
                      } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(0)}k`; // Format values in thousands
                      }
                      return value; // For values below 1000, return as-is
                    },
                  },
                ]}
                series={[
                  {
                    data: [
                      metrics?.verifiedActiveLoanAmount[0]?.total || 0,
                      metrics?.verifiedExtensionLoanAmount[0]?.total || 0,
                      metrics?.verifiedOverdueLoanAmount[0]?.total || 0,
                      metrics?.verifiedDefaultedLoanAmount[0]?.total || 0,
                    ],
                    label: "Premium",
                    color: "blue",
                  },
                  {
                    data: [
                      metrics?.notVerifiedActiveLoanAmount[0]?.total || 0,
                      metrics?.notVerifiedExtensionLoanAmount[0]?.total || 0,
                      metrics?.notVerifiedOverdueLoanAmount[0]?.total || 0,
                      metrics?.notVerifiedDefaultedLoanAmount[0]?.total || 0,
                    ],
                    label: "Regular",
                    color: "orange",
                  },
                ]}
                slotProps={{
                  legend: {
                    labelStyle: { fontSize: "small" },
                  },
                }}
                height={250}
              />
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Loan Count</Typography>
              <LineChart
                xAxis={[
                  {
                    data: ["Active", "Extended", "Overdue", "Defaulted"],
                    scaleType: "band", // Category-like axis for status labels
                  },
                ]}
                yAxis={[
                  {
                    label: "Loan Count",
                    valueFormatter: (value) => {
                      if (value >= 1000000) {
                        return `${(value / 1000000).toFixed(1)}m`; // Format in millions
                      } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(1)}k`; // Format in thousands
                      }
                      return value; // Raw value for small numbers
                    },
                  },
                ]}
                series={[
                  {
                    data: [
                      metrics?.verifiedActiveLoanAmount[0]?.loanCount || 0,
                      metrics?.verifiedExtensionLoanAmount[0]?.loanCount || 0,
                      metrics?.verifiedOverdueLoanAmount[0]?.loanCount || 0,
                      metrics?.verifiedDefaultedLoanAmount[0]?.loanCount || 0,
                    ],
                    label: "Premium",
                    color: "blue",
                  },
                  {
                    data: [
                      metrics?.notVerifiedActiveLoanAmount[0]?.loanCount || 0,
                      metrics?.notVerifiedExtensionLoanAmount[0]?.loanCount ||
                        0,
                      metrics?.notVerifiedOverdueLoanAmount[0]?.loanCount || 0,
                      metrics?.notVerifiedDefaultedLoanAmount[0]?.loanCount ||
                        0,
                    ],
                    label: "Regular",
                    color: "orange",
                  },
                ]}
                slotProps={{
                  legend: {
                    labelStyle: { fontSize: "small" },
                  },
                }}
                height={250}
              />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = ({ label, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          border: `1px solid ${color}`,
          color: color,
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
            <People sx={{ fontSize: "2em" }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};

const ActivityItem = ({ label, total, l1, l2, v1, v2, color }) => {
  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          p: 2,
          borderRadius: 3,
          border: "1px solid #60606020",
          backgroundColor: "white",
        }}
      >
        <Stack spacing={2}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
          </Box>
          <Divider />
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: color }}
          >
            {(total - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2" flexGrow={1}>
              {l1}
            </Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: color, // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v1 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v1 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2">{l2}</Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FF8000", // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v2 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v2 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid2>
  );
};
