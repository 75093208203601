import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Pagination,
} from "@mui/material";

export default function Payments({ user }) {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [expandedPaymentId, setExpandedPaymentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const offset = (currentPage - 1) * limit;
      const response = await fetch(
        `/api/payments?userId=${user.id}&offset=${offset}&limit=${limit}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch payments");
      const { data } = await response.json();
      setPayments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [user.id, currentPage]);

  const handleExpandClick = (paymentId) => {
    setExpandedPaymentId((prevId) => (prevId === paymentId ? null : paymentId));
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Payments
      </Typography>
      <Stack spacing={2}>
        {payments.map((payment) => (
          <Card
            key={payment.id}
            sx={{
              position: "relative",
              boxShadow: "0px 4px 16px #60606040",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ mb: 0, pb: 0 }}>
              <Typography
                sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                variant="body2"
                color="text.secondary"
              >
                {new Date(payment.createdAt).toLocaleString()}
              </Typography>
              <Typography display="block" variant="title">
                <strong style={{ fontWeight: 500 }}>Amount Paid:</strong> KSh{" "}
                {payment.amountPaid}
              </Typography>

              <Chip
                sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                label={payment.mpesaReceiptNumber}
              />
              <Button
                size="small"
                onClick={() => handleExpandClick(payment.id)}
              >
                {expandedPaymentId === payment.id
                  ? "Hide Loan Details"
                  : "View Loan Details"}
              </Button>
            </CardContent>
            <Collapse
              in={expandedPaymentId === payment.id}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Loan Details
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong style={{ fontWeight: 500 }}>Order Number:</strong>{" "}
                  {payment.loan.orderNumber}
                </Typography>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 500 }}>Amount:</strong> KSh{" "}
                  {payment.loan.amount}
                </Typography>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 500 }}>Repayment Date:</strong>{" "}
                  {payment.loan.repaymentDate}
                </Typography>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 500 }}>Remaining Amount:</strong>{" "}
                  KSh {payment.loan.remainingAmount}
                </Typography>
                <Chip
                  label={payment.loan.status}
                  sx={{ mt: 1 }}
                  color={
                    payment.loan.status === "Pending"
                      ? "warning"
                      : payment.loan.status === "Paid"
                      ? "success"
                      : "default"
                  }
                />
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Stack>
      <Pagination
        count={payments ? Math.ceil(payments.total / limit) : 1}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
      />
    </Box>
  );
}
