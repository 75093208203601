import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { debounce } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Refresh } from "@mui/icons-material";
import dayjs from "dayjs";

export default function CSMessages() {
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("subject"); // Default search column
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, [page, rowsPerPage, searchText, searchColumn, startDate, endDate, refresh]);

  const fetchMessages = () => {
    setLoading(true);

    const url = new URL("/api/messages", window.location.origin);
    url.searchParams.append("offset", page * rowsPerPage);
    url.searchParams.append("limit", rowsPerPage);
    if (searchText) {
      url.searchParams.append(searchColumn, searchText); // Dynamic column-based search
    }
    if (startDate) {
      url.searchParams.append("startDate", startDate.format("YYYY-MM-DD"));
    }
    if (endDate) {
      url.searchParams.append("endDate", endDate.format("YYYY-MM-DD"));
    }

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching messages");
      })
      .then((data) => {
        setLoading(false);
        setMessages(data?.data);
        setCount(data?.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0);
  }, 500);

  const handleRowClick = (message) => {
    setSelectedMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMessage(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 9 }}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mb: 2 }}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </Box>
        <Box
          marginTop={2}
          padding={2}
          boxShadow="0px 10px 30px #60606040"
          borderRadius="12px"
          bgcolor="white"
        >
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h5" flexGrow={1} gutterBottom>
              Activities
            </Typography>
            <FormControl size="small" sx={{ marginRight: 2, minWidth: 120 }}>
              <InputLabel>Search by</InputLabel>
              <Select
                value={searchColumn}
                onChange={(e) => setSearchColumn(e.target.value)}
                label="Search by"
              >
                <MenuItem value="subject">Subject</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="message">Content</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Search..."
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
            />
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">User</TableCell>
                  <TableCell align="left">Subject</TableCell>
                  <TableCell align="left">Content</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  messages.map((message) => (
                    <TableRow
                      key={message.ID}
                      hover
                      onClick={() => handleRowClick(message)}
                    >
                      <TableCell align="left">
                        {message?.User?.firstName} {message?.User?.lastName}
                      </TableCell>
                      <TableCell align="left">
                        {message.Subject}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          maxWidth: 200,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {message.Message}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleRowClick(message)}>
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {messages.length === 0 && !loading && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No messages found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />

          {selectedMessage && (
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
              <DialogTitle>Message Details</DialogTitle>
              <DialogContent>
                <Typography variant="h6">
                  {selectedMessage.User.firstName} {selectedMessage.User.lastName}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  {selectedMessage.Subject}
                </Typography>
                <Typography variant="body1" paragraph>
                  {selectedMessage.Message}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Email: {selectedMessage.User.email}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Phone: {selectedMessage.User.phoneNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Date: {dayjs(selectedMessage.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Typography>
                <Box mt={2}>
                  <Chip 
                    label={selectedMessage.Status ? "Read" : "Unread"} 
                    color={selectedMessage.Status ? "success" : "warning"} 
                    size="small" 
                  />
                  {selectedMessage.SMS && <Chip label="SMS" color="primary" size="small" sx={{ ml: 1 }} />}
                  {selectedMessage.Email && <Chip label="Email" color="primary" size="small" sx={{ ml: 1 }} />}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
