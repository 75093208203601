import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  Grid2,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  Add as AddIcon,
  Close,
  Email,
  Message,
  Phone,
  WhatsApp,
  Description,
  Visibility,
} from "@mui/icons-material";

export default function Applications() {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [upgradeApplications, setUpgradeApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [reviewNotes, setReviewNotes] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewBlob, setPreviewBlob] = useState(null);

  // Fetch upgrade applications
  useEffect(() => {
    setLoading(true);
    fetch(`/api/applications?offset=${offset * 10}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setUpgradeApplications(data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh]);

  const handleReviewApplication = (status) => {
    setUpdateLoading(true);
    fetch(`/api/applications/${selectedApplication.id}/review`, {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        status,
        reviewNotes,
      }),
    })
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        setError("Application reviewed successfully!");
        setTimeout(() => {
          setOpenUpgradeDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setUpdateLoading(false));
  };

  const handlePreviewDocument = (url) => {
    setPreviewUrl(url);
    setOpenPreviewDialog(true);
    fetch(`/api/${url}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.blob())
      .then((blob) => {
        setPreviewBlob(URL.createObjectURL(blob));
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
      });
  };

  return (
    <Box>
      <Box sx={{ padding: "0" }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Employment Status</TableCell>
                  <TableCell>Company/Business Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reviewer</TableCell>
                  <TableCell>Review Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upgradeApplications.length > 0 ? (
                  upgradeApplications.map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell padding="checkbox">
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell>{item.fullName}</TableCell>
                      <TableCell>{item.employmentStatus}</TableCell>
                      <TableCell>
                        {item.employmentStatus === "Employed"
                          ? item.companyName
                          : item.businessName}
                      </TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{item.reviewer?.Name || "N/A"}</TableCell>
                      <TableCell>
                        {item.reviewedAt
                          ? new Date(item.reviewedAt).toLocaleDateString()
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setSelectedApplication(item);
                            setOpenUpgradeDialog(true);
                          }}
                        >
                          Review
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>No Applications Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {upgradeApplications.length > 0 && (
            <Pagination
              count={Math.ceil(upgradeApplications.length / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      <Dialog
        open={openUpgradeDialog}
        onClose={() => setOpenUpgradeDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h5">Review Upgrade Application</Typography>
        </DialogTitle>
        <Divider sx={{ mb: 2 }} />
        <DialogContent>
          <Stack spacing={2}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>Full Name:</strong> {selectedApplication?.fullName}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>Employment Status:</strong>{" "}
                  {selectedApplication?.employmentStatus}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>Phone Number:</strong>{" "}
                  {selectedApplication?.user?.phoneNumber}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>
                    {selectedApplication?.employmentStatus === "Employed"
                      ? "Company Name"
                      : "Business Name"}
                    :
                  </strong>{" "}
                  {selectedApplication?.employmentStatus === "Employed"
                    ? selectedApplication?.companyName
                    : selectedApplication?.businessName}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>Guarantor Name:</strong>{" "}
                  {selectedApplication?.guarantorName}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Typography variant="body1">
                  <strong>Guarantor Phone:</strong>{" "}
                  {selectedApplication?.guarantorPhone}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <Typography variant="subtitle1">Documents</Typography>
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  flexWrap="wrap"
                >
                  {selectedApplication?.nationalIdPhoto && (
                    <Card sx={{ m: 1, maxWidth: 300, flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={selectedApplication.nationalIdPhoto}
                        alt="National ID"
                      />
                      <CardContent>
                        <Typography variant="body2">National ID</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          startIcon={<Visibility />}
                          onClick={() =>
                            handlePreviewDocument(
                              selectedApplication.nationalIdPhoto
                            )
                          }
                        >
                          Preview
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {selectedApplication?.bankStatementDoc && (
                    <Card sx={{ m: 1, maxWidth: 300, flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={selectedApplication.bankStatementDoc}
                        alt="Bank Statement"
                      />
                      <CardContent>
                        <Typography variant="body2">Bank Statement</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          startIcon={<Visibility />}
                          onClick={() =>
                            handlePreviewDocument(
                              selectedApplication.bankStatementDoc
                            )
                          }
                        >
                          Preview
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {selectedApplication?.guarantorIdPhoto && (
                    <Card sx={{ m: 1, maxWidth: 300, flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={selectedApplication.guarantorIdPhoto}
                        alt="Guarantor ID"
                      />
                      <CardContent>
                        <Typography variant="body2">Guarantor ID</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          startIcon={<Visibility />}
                          onClick={() =>
                            handlePreviewDocument(
                              selectedApplication.guarantorIdPhoto
                            )
                          }
                        >
                          Preview
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {selectedApplication?.payslipDoc && (
                    <Card sx={{ m: 1, maxWidth: 300, flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={selectedApplication.payslipDoc}
                        alt="Payslip"
                      />
                      <CardContent>
                        <Typography variant="body2">Payslip</Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          startIcon={<Visibility />}
                          onClick={() =>
                            handlePreviewDocument(
                              selectedApplication.payslipDoc
                            )
                          }
                        >
                          Preview
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <TextField
                  label="Review Notes"
                  multiline
                  rows={4}
                  value={reviewNotes}
                  onChange={(e) => setReviewNotes(e.target.value)}
                  fullWidth
                />
              </Grid2>
            </Grid2>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleReviewApplication("Rejected")}
            disabled={updateLoading}
          >
            Reject
          </Button>
          <Button
            variant="outlined"
            color="success"
            onClick={() => handleReviewApplication("Approved")}
            disabled={updateLoading}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenUpgradeDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent>
          {previewBlob ? (
            <iframe src={previewBlob} width="100%" height="500px" />
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenPreviewDialog(false);
              setPreviewBlob(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
