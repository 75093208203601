import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const MyLineChart = ({ title }) => {
  const [period, setPeriod] = useState("1 Year");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/loans/loan-summary?period=${period}`);
      if (!response.ok) throw new Error("Error fetching loan summary data");
      const data = await response.json();
      setChartData(data);
    } catch (error) {
      console.error("Error fetching loan summary data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [period]);

  const periodButtons = ["1 Year", "6 Months", "3 Months", "1 Month", "This Week"];

  return (
    <Card sx={{ borderRadius: 3, boxShadow: "0px 4px 8px #60606030" }}>
      <CardContent sx={{ position: "relative" }}>
        <Box display="flex" gap={1}>
          <Typography flexGrow={1} variant="title" gutterBottom>
            {title ?? "Overview"}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {periodButtons.map((btn) => (
              <Button
                key={btn}
                onClick={() => setPeriod(btn)}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "32px",
                  fontSize: "x-small",
                }}
                variant={period === btn ? "contained" : "outlined"}
              >
                {btn}
              </Button>
            ))}
          </Box>
        </Box>

        <Box display="flex" my={2} alignItems="center">
          <Box flexGrow={1}></Box>
        </Box>

        {loading ? (
          <Box height={335} sx={{ display: "grid", placeContent: "center" }}>
            <Typography variant="body2">Loading data...</Typography>
          </Box>
        ) : !chartData.length ? (
          <Box height={335} sx={{ display: "grid", placeContent: "center" }}>
            <Typography variant="body2">No data available</Typography>
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={335}>
            <LineChart data={chartData} height={300}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={(value) => {
                  if (value >= 1000000)
                    return `${(value / 1000000).toFixed(0)}m`;
                  else if (value >= 1000)
                    return `${(value / 1000).toFixed(0)}k`;
                  return value;
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="amount"
                stroke="#8884d8"
                name="Amount"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="interest"
                stroke="#82ca9d"
                name="Interest"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="penalties"
                stroke="#ffc658"
                name="Penalties"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="paymentAmount"
                stroke="#ff7300"
                name="Payment Amount"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default MyLineChart;
