import React, { useEffect, useState } from "react";
import { ArrowForwardIos, LineAxis, MoneyOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid2,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Activity } from "@phosphor-icons/react";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";

export default function Loans({ refresh }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/loans/loan-dashboard-summary");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid2 container spacing={3}>
      <CardItem
        label="Principal"
        value={data.principal.total}
        current={data.principal.current}
        last={data.principal.last}
        color="#8B5DFF"
        positive={true}
      />
      <CardItem
        label="Paid"
        value={data.paid.total}
        current={data.paid.current}
        last={data.paid.last}
        color="#3D3BF3"
        positive={true}
      />
      <CardItem
        label="Defaulted"
        value={data.defaulted.total}
        current={data.defaulted.current}
        last={data.defaulted.last}
        color="#219B9D"
        positive={true}
      />
      <CardItem
        label="Income"
        value={data.income.total}
        current={data.income.current}
        last={data.income.last}
        color="#FF8000"
      />
      <Grid2 size={{ xs: 12, md: 8 }}>
        <MyLineChart title="Loan Perfomance" />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <MyPieChart
          data={[
            { name: "Paid", value: data.paid.total },
            { name: "Not Paid", value: data.principal.repay - data.paid.total },
          ]}
          height={370}
          title="Repayment Rate"
        />
      </Grid2>
    </Grid2>
  );
}

const CardItem = ({ label, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: color,
          color: "white",
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
            <MoneyOff sx={{ fontSize: "2em" }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "white" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "white" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};


