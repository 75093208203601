import React, { useState } from "react";
import { Tabs, Tab, Box, Card } from "@mui/material";
import Users from "./Users";
import Agents from "./Agents";
import Customers from "./Customers";

export default function UsersHome(props) {
  const [selected, setSelected] = useState(0);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  return (
    <Box mt={9}>
      <Tabs value={selected} onChange={handleChange} aria-label="user tabs">
        <Tab label="Staff" sx={{ textTransform: "capitalize" }} />
        <Tab label="Agents" sx={{ textTransform: "capitalize" }} />
      </Tabs>
      <Box
        sx={{
          padding: "16px",
          borderRadius: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
        mt={2}
      >
        {selected === 0 && <Users />}
        {selected === 1 && <Agents />}
      </Box>
    </Box>
  );
}
