import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CircularProgress,
  IconButton,
  Divider,
  Chip,
} from "@mui/material";
import UserDetails from "../CustomerSupport/Tabs/UserDetails";
import Loans from "../CustomerSupport/Tabs/Loans";
import Payments from "../CustomerSupport/Tabs/Payments";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Support from "../CustomerSupport/Tabs/Support";
import Activities from "../CustomerSupport/Tabs/Activities";

export default function RMCustomerManagement(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const loanId = window.location.pathname.split("/")[3];

  const fetchUserDetails = async (userId) => {
    try {
      const response = await fetch(
        `/api/users?id=${userId}&includeLoans=true&includeCreditScore=true&includePayments=true`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch user details");
      const data = await response.json();
      setUser(data?.data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails(loanId);
  }, [loanId, refresh]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mt: 9,
        p: 3,
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        position: "relative",
      }}
      component={Card}
    >
      <Box display="flex" gap={1}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
          sx={{
            display: "block",
            width: "fit-content",
            textTransform: "capitalize",
          }}
        >
          <Tab sx={{ textTransform: "capitalize" }} label="Details" />
          <Tab sx={{ textTransform: "capitalize" }} label="Loans" />
          <Tab sx={{ textTransform: "capitalize" }} label="Payments" />
          <Tab sx={{ textTransform: "capitalize" }} label="Support" />
          <Tab sx={{ textTransform: "capitalize" }} label="Activity" />
        </Tabs>
        <Box flexGrow={1}></Box>
        <IconButton
          onClick={() => {
            navigate("/rm/customers");
          }}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      <Divider sx={{ mt: -1 }} />

      <Box mt={2}>
        {selectedTab === 0 && (
          <UserDetails user={user} refresh={refresh} setRefresh={setRefresh} />
        )}
        {selectedTab === 1 && <Loans user={user} />}
        {selectedTab === 2 && <Payments user={user} />}
        {selectedTab === 3 && <Support user={user} />}
        {selectedTab === 4 && <Activities user={user} />}
      </Box>
    </Box>
  );
}
