import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  Alert,
  Grid2,
} from "@mui/material";
import {
  Add as AddIcon,
  Close,
  Email,
  Message,
  Phone,
  WhatsApp,
} from "@mui/icons-material";

export default function Customers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [bloading, setBLoading] = useState(false);
  const [status, setStatus] = useState("All");
  const [customerDetails, setCustomerDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    idCardNumber: "",
    phoneNumber: "",
    email: "",
    gender: "",
    county: "",
    subcounty: "",
    ward: "",
    monthlyIncome: "",
    dateOfBirth: "",
    detailedAddress: "",
    alternativePhone: "",
    typeOfWork: "",
    payDay: "",
    emergencyContact1: { name: "", phone: "" },
    emergencyContact2: { name: "", phone: "" },
    isVerified: false,
    role: "",
    blacklisted: false,
    createdAt: "",
    updatedAt: "",
    deletedAt: null
  });

  // Fetch customers data
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/users?offset=${offset * 10}${
        status == "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, status]);

  // Search functionality
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}${
          status == "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => setData(data))
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  // Handle row click to view customer details
  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerDetails({
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      idCardNumber: customer.idCardNumber,
      phoneNumber: customer.phoneNumber,
      email: customer.email,
      gender: customer.gender,
      county: customer.county,
      subcounty: customer.subcounty,
      ward: customer.ward,
      monthlyIncome: customer.monthlyIncome,
      dateOfBirth: customer.dateOfBirth,
      detailedAddress: customer.detailedAddress,
      alternativePhone: customer.alternativePhone,
      typeOfWork: customer.typeOfWork,
      payDay: customer.payDay,
      emergencyContact1: customer.emergencyContact1 || { name: "", phone: "" },
      emergencyContact2: customer.emergencyContact2 || { name: "", phone: "" },
      isVerified: customer.isVerified,
      role: customer.role,
      blacklisted: customer.blacklisted,
      createdAt: customer.createdAt,
      updatedAt: customer.updatedAt,
      deletedAt: customer.deletedAt
    });
    setOpenDetailDialog(true);
  };

  // Handle customer update
  const handleSaveChanges = () => {
    setUpdateLoading(true);
    fetch(`/api/users/${selectedCustomer.id}`, {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(customerDetails),
    })
      .then(async (res) => {
        if (!res.ok) throw new Error(await res.text());
        setError("Customer updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setUpdateLoading(false));
  };

  // Handle customer blacklisting
  const handleBlacklistCustomer = (status, id) => {
    setBLoading(true);
    fetch(`/api/users/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ blacklisted: status }),
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then(() => {
        setError("Status updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setBLoading(false));
  };

  // Handle customer deletion
  const handleDeleteCustomer = () => {
    setDeleteLoading(true);
    fetch(`/api/users/${selectedCustomer.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) throw new Error("Failed to delete customer");
        setError("Customer deleted successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setDeleteLoading(false));
  };

  return (
    <Box>
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Type</InputLabel>
            <Select
              label="Type"
              size="small"
              onChange={(e) => {
                setOffset(0);
                setStatus(e.target.value);
              }}
              value={status}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => {
                setOffset(0);
                setColumn(e.target.value);
              }}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>County</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell padding="checkbox">Contact</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        padding="checkbox"
                      >
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        sx={{
                          color: item.blacklisted ? 'red' : 'inherit',
                          textDecoration: item.blacklisted ? 'line-through' : 'none',
                        }}
                      >{`${item.firstName} ${item.lastName}`}</TableCell>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        sx={{
                          color: item.blacklisted ? 'red' : 'inherit',
                          textDecoration: item.blacklisted ? 'line-through' : 'none',
                        }}
                      >
                        {item.idCardNumber}
                      </TableCell>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        sx={{
                          color: item.blacklisted ? 'red' : 'inherit',
                          textDecoration: item.blacklisted ? 'line-through' : 'none',
                        }}
                      >
                        {item.phoneNumber.replace("+", "").replace("254", "0")}
                      </TableCell>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        sx={{
                          color: item.blacklisted ? 'red' : 'inherit',
                          textDecoration: item.blacklisted ? 'line-through' : 'none',
                        }}
                      >
                        {item.gender}
                      </TableCell>
                      <TableCell
                        onClick={() => handleRowClick(item)}
                        sx={{
                          color: item.blacklisted ? 'red' : 'inherit',
                          textDecoration: item.blacklisted ? 'line-through' : 'none',
                        }}
                      >
                        {item.county}
                      </TableCell>
                      <TableCell onClick={() => handleRowClick(item)}>
                        <Chip
                          color={item.isVerified ? "success" : "warning"}
                          label={item.isVerified ? "Premium" : "Regular"}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <IconButton
                            variant="contained"
                            color="primary"
                            size="small"
                            title={item.phoneNumber}
                            onClick={() =>
                              window.open(
                                `tel:+${item.phoneNumber
                                  .slice(1)
                                  .replace("+", "")}`,
                                "_self"
                              )
                            }
                          >
                            <Phone />
                          </IconButton>
                          <IconButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            title={item.phoneNumber}
                            onClick={() =>
                              window.open(
                                `sms:+${item.phoneNumber
                                  .slice(1)
                                  .replace("+", "")}`,
                                "_self"
                              )
                            }
                          >
                            <Message />
                          </IconButton>
                          <IconButton
                            variant="contained"
                            color="success"
                            size="small"
                            title={item.phoneNumber}
                            onClick={() =>
                              window.open(
                                `https://wa.me/+${item.phoneNumber
                                  .slice(1)
                                  .replace("+", "")}`,
                                "_blank"
                              )
                            }
                          >
                            <WhatsApp />
                          </IconButton>
                          <IconButton
                            variant="contained"
                            color="error"
                            size="small"
                            title={item.email}
                            onClick={() =>
                              window.open(`mailto:${item.email}`, "_self")
                            }
                          >
                            <Email />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>No Customers Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      {selectedCustomer && (
        <Dialog
          open={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
          fullWidth="md"
        >
          <DialogTitle display="flex">
            <Typography variant="h5" flexGrow={1} sx={{ margin: "auto" }}>
              Customer Details
            </Typography>
            <IconButton onClick={() => setOpenDetailDialog(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 3 }}>
                  <TextField
                    label="First Name"
                    fullWidth
                    value={customerDetails.firstName}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3 }}>
                  <TextField
                    label="Last Name"
                    fullWidth
                    value={customerDetails.lastName}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        lastName: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3 }}>
                  <TextField
                    label="ID No"
                    fullWidth
                    value={customerDetails.idCardNumber}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        idCardNumber: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3 }}>
                  <TextField
                    label="Date of Birth"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={customerDetails.dateOfBirth}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        dateOfBirth: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Phone"
                    fullWidth
                    value={customerDetails.phoneNumber}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Alternative Phone"
                    fullWidth
                    value={customerDetails.alternativePhone}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        alternativePhone: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 8 }}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={customerDetails.email}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <TextField
                    label="Gender"
                    fullWidth
                    value={customerDetails.gender}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        gender: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <TextField
                    label="County"
                    fullWidth
                    value={customerDetails.county}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        county: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <TextField
                    label="Subcounty"
                    fullWidth
                    value={customerDetails.subcounty}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        subcounty: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <TextField
                    label="Ward"
                    fullWidth
                    value={customerDetails.ward}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        ward: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Detailed Address"
                    fullWidth
                    value={customerDetails.detailedAddress}
                    onChange={(e) =>
                      setCustomerDetails({
                        ...customerDetails,
                        detailedAddress: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel>Income</InputLabel>
                    <Select
                      label="Income"
                      fullWidth
                      value={customerDetails.monthlyIncome}
                      onChange={(e) =>
                        setCustomerDetails({
                          ...customerDetails,
                          monthlyIncome: e.target.value,
                        })
                      }
                    >
                      {["Less than 50,000", "More than 50,000"].map(
                        (income) => (
                          <MenuItem key={income} value={income}>
                            {income}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel>Type of Work</InputLabel>
                    <Select
                      label="Type of Work"
                      value={customerDetails.typeOfWork}
                      onChange={(e) =>
                        setCustomerDetails({
                          ...customerDetails,
                          typeOfWork: e.target.value,
                        })
                      }
                      fullWidth
                    >
                      {["Full Time", "Part Time", "Self Employed"].map(
                        (workType) => (
                          <MenuItem key={workType} value={workType}>
                            {workType}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Pay Day"
                    fullWidth
                    type="number"
                    value={customerDetails.payDay}
                    onChange={(e) => {
                      setCustomerDetails({
                        ...customerDetails,
                        payDay: e.target.value,
                      });
                    }}
                  />
                </Grid2>
              </Grid2>
              {error && (
                <Alert
                  severity={error.includes("success") ? "success" : "error"}
                >
                  {error}
                </Alert>
              )}
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={() =>
                handleBlacklistCustomer(
                  !selectedCustomer.blacklisted,
                  selectedCustomer.id
                )
              }
              variant="outlined"
            >
              {bloading
                ? "Updating status..."
                : !selectedCustomer.blacklisted
                ? "Blacklist"
                : "Whitelist"}
            </Button>
            <Button
              color="primary"
              sx={{ color: "white", textTransform: "capitalize" }}
              onClick={handleSaveChanges}
              variant="contained"
              disabled={updateLoading}
            >
              {updateLoading ? "Saving Changes..." : "Save Changes"}
            </Button>
            <Button
              color="error"
              sx={{ color: "white", textTransform: "capitalize" }}
              onClick={handleDeleteCustomer}
              variant="contained"
              disabled={deleteLoading}
            >
              {deleteLoading ? "Deleting User..." : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
